import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import Cookies from 'js-cookie'
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // timeout: 5000 // request timeout
})

service.interceptors.request.use(
  config => {
    config.headers['WalletAddress'] = localStorage.getItem("wallet_address")
    config.headers['token'] = localStorage.getItem("wallet_address")
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
    config.headers['Language'] = store.getters.language 
    config.headers['uid'] = localStorage.getItem('u_id')
    // config.headers["Content-Type"] = 'multipart/form-data'
    // if (store.getters.token) {
    //   //   config.headers['X-Token'] = getToken()
    // }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)
service.interceptors.response.use(
  response => {
    const res = response.data


    if (res.code === 200) {
      // console.log('res', res)
      return res
    }
    else if (res.code === 1101 || res.code === 1102 || res.code === 1103 || res.code === 1104 || res.code === 1105 ) {
      // 1101、1102、1103、1104、1105
      // Cookies.set('token','')
      Cookies.remove('token')
      store.dispatch('user/logout')
      store.dispatch('mask/openMask', 'walletBind').then((res) => {
        // console.log('walletBind', '弹出')
      })
      return res
    } else if (res.code === 1107) {
      store.dispatch('mask/openMask', 'authCode')
      const { token } = res.data;
      Cookies.set("token", token);
      // console.log('authCode res===>',res.data, 'Cookies ===> ',Cookies.get('token'));
      return res
    } else if(res.code === 1001){
      Message({
        message: res.message,
        type: 'error',
        duration: 3 * 1000
      })
      return res
    }else if(res.code === 1309||res.code==1401){
      return res
    } else {
      Message({
        message: res.message,
        type: 'error',
        duration: 3 * 1000
      })
      return res
    }


    // "1101" => [
    //     "name" => "ERR_NEED_LOGIN",
    //     "code" => 1101,
    //     "msg" => "需要登录"
    // ],
    // "1102" => [
    //     "name" => "ERR_TOKEN_EXPIRE",
    //     "code" => 1102,
    //     "msg" => "TOKEN过期"
    // ],
    // "1103" => [
    //     "name" => "ERR_TOKEN_VERIFICATION_FAIL",
    //     "code" => 1103,
    //     "msg" => "TOKEN验证失败"
    // ],
    // if (res.code !== 20000) {
    //   Message({
    //     message: res.message || 'Error',
    //     type: 'error',
    //     duration: 5 * 1000
    //   })

    //   if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //     MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
    //       confirmButtonText: 'Re-Login',
    //       cancelButtonText: 'Cancel',
    //       type: 'warning'
    //     }).then(() => {
    //       //   store.dispatch('user/resetToken').then(() => {
    //       //     location.reload()
    //       //   })
    //     })
    //   }
    //   return Promise.reject(new Error(res.message || 'Error'))
    // } else {
    //   return res
    // }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 3 * 1000
    })
    return Promise.reject(error)
  }
)

export default service