const Web3 = require("web3");
const web3 = new Web3(Web3.givenProvider);
const mintABI = require("@/abi/mint.json");
// const stakeABI = require("@/abi/stake.json");
import {stake_a ,redeem_a} from "@/api/trade/index.js";
const {
    ethereum
} = window;

import {
    checkAllowance,
    queryBalanceOf, wallet_addEthereumChain_ETH, wallet_addEthereumChain_Matic
} from '@/utils/web3.js'

// 请求账号
export async function getCurrentAccount() {
    let accounts = await ethereum.request({
        method: "eth_accounts",
    });
    return accounts[0]
}

// 请求账号
export async function connectWallet() {
    await ethereum.request({
        method: "eth_requestAccounts",
    });
    return
}



async function eth_sign(data) {
    return new Promise((resolution, rejection) => {
        console.log('currentProvider', web3.currentProvider)
        web3.currentProvider.send(data, function (err, res) {
            if (err) {
                rejection(err)
                return
            }
            resolution(res)
        })
    });
}


export async function checkChain() {
    let network = await ethereum.request({
        method: "net_version"
    });
    console.log(process.env);
    if (network !== process.env.VUE_APP_MAIN_NETWORK) {
        await ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{
                chainId: web3.utils.numberToHex(process.env.VUE_APP_MAIN_NETWORK),
            },],
        });
    }
}

//验证是否授权
export async function isApprovedForAll(account,nft_contract,stake_contract) {
    var contract = nftContract(nft_contract);
    return new Promise(function (resolve, reject) {
      contract.methods
        .isApprovedForAll(account, stake_contract)
        .call({ from: account }, function (error, result) {
          if (error) {
            reject(error);
          } else {
            resolve(result);
          }
        });
    });
  }

  //合约创建
export function nftContract(nft_contract) {
    var web3 = new Web3(window.ethereum);
    let contract_address = nft_contract;
    console.log("contract_address", contract_address);
    return new web3.eth.Contract(mintABI, contract_address);
  }

//授权
export function setApprovalForAll(account,nft_contract,stake_contract) {
    var contract = nftContract(nft_contract);
    return contract.methods
      .setApprovalForAll(stake_contract, true)
      .send({ from: account });
  }

//质押nft
export async function stakeNFT(account, ids,contractAddress, callback) {
    // var contract = actionContract(),
    let res = await stake_a({ ids })
    console.log("质押返回",res);
    if (res.code == 200) {
        var contract = stakeContract(contractAddress);
      let { token_ids,} = res.data;
      var sender = contract.methods
        .localStake(token_ids)
        .send({ from: account });
      callback && callback(sender)
      return sender
    } else {
      console.log("error", res);
    }
  }

  //取回nft
export async function redeemNFT(account, ids,contractAddress, callback) {
    // var contract = actionContract(),
    let res = await redeem_a({ ids })
    console.log("质押返回",res);
    if (res.code == 200) {
        var contract = stakeContract(contractAddress);
      let { token_ids} = res.data;
      var sender = contract.methods
        .localRedeem(token_ids)
        .send({ from: account });
      callback && callback(sender)
      return sender
    } else {
      console.log("error", res);
    }
  }

// 质押合约创建
export function stakeContract(stake_contract) {
    var web3 = new Web3(window.ethereum);
    return new web3.eth.Contract(mintABI, stake_contract);
}



  