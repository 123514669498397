const Web3 = require("web3");
const mintABI = require("@/abi/mint.json");
let {
  ethereum
} = window;

/**
 *  const myContract = new web3.eth.Contract(ABI, Address);
 *  Address -合约地址
 */

export function whiteListReserve(orderInfo, callback) {
  console.log(orderInfo)
  const {
    price,
    buyer_address,
    contract_address,
    hash,
    nonce,
    quantity,
    signature,
    block_height
  } = orderInfo;
  var web3 = new Web3(window.ethereum);
  const myContract = new web3.eth.Contract(mintABI, contract_address);
  return myContract.methods
    .whiteListReserve(quantity, hash, signature, block_height, nonce)
    .send({
      from: buyer_address,
      // gasLimit: '21000',
      value: price.toString(),
      type: "0x2",
    })
}

export function waitListReserve(orderInfo, callback) {
  console.log(orderInfo)
  const {
    price,
    buyer_address,
    contract_address,
    hash,
    nonce,
    quantity,
    signature,
    block_height,
  } = orderInfo;
  var web3 = new Web3(window.ethereum);
  const myContract = new web3.eth.Contract(mintABI, contract_address);
  return myContract.methods
    .waitListReserve(quantity, hash, signature, block_height, nonce)
    .send({
      from: buyer_address,
      // gasLimit: '21000',
      value: price.toString(),
      type: "0x2",
    })
}

export function reserve(orderInfo, callback) {
  console.log(orderInfo)
  const {
    price,
    buyer_address,
    contract_address,
    hash,
    nonce,
    quantity,
    block_height
  } = orderInfo;
  var web3 = new Web3(window.ethereum);
  const myContract = new web3.eth.Contract(mintABI, contract_address);
  return myContract.methods
    .reserve(quantity)
    .send({
      from: buyer_address,
      // gasLimit: '21000',
      value: price.toString(),
      type: "0x2",
    })
}

export function claim(walletAddress, quantity, contractAddress) {
  var web3 = new Web3(window.ethereum);
  const myContract = new web3.eth.Contract(mintABI, contractAddress);
  return myContract.methods
    .claim(quantity)
    .send({
      from: walletAddress,
      // gasLimit: '21000',
      type: "0x2",
    })
}

//获取预约数量
export async function getReservedQuantity(walletAddress, contractAddress) {
  console.log(walletAddress, contractAddress);
  var web3 = new Web3(window.ethereum);
  const contract = new web3.eth.Contract(mintABI, contractAddress);
  return new Promise(function (resolve, reject) {
    contract.methods
      .checkReservedQuantity(walletAddress)
      .call({
        from: walletAddress
      }, function (error, result) {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      });
  });
}

//获取领取数量
export async function getClaimedQuantity(walletAddress, contractAddress) {
  console.log(walletAddress, contractAddress);
  var web3 = new Web3(window.ethereum);
  const contract = new web3.eth.Contract(mintABI, contractAddress);
  return new Promise(function (resolve, reject) {
    contract.methods
      .checkClaimedQuantity(walletAddress)
      .call({
        from: walletAddress
      }, function (error, result) {
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      });
  });
}
