import request from '@/utils/request'
import baseURL from '@/utils/baseURL'


export function place_goods_order(data) {
  return request({
    url: baseURL["trade"] + `/v2/order/place_goods_order`,
    method: "post",
    data,
  });
}
export function place_goods_order_submit(data) {
  return request({
    url: baseURL["trade"] + `/v2/order/place_goods_order_submit_nonce`,
    method: "post",
    data,
  });
}

//质押北海
export function stake_a(data) {
  return request({
    url: baseURL["trade"] + `/v2/stake/stake_721a`,
    method: "post",
    data,
  });
}

//取回北海
export function redeem_a(data) {
  return request({
    url: baseURL["trade"] + `/v2/stake/redeem_721a`,
    method: "post",
    data,
  });
}
//SBT
export function redeem_activity_nft(data) {
  return request({
    url: baseURL["trade"] + `/v2/peking_monsters/redeem_activity_nft`,
    method: "post",
    data,
  });
}